import React, { useState } from 'react';
import styles from './choose-appt.module.scss';

import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { Calendar } from '../../../../../shared/components/widgets/calendar/calendar.component';
import Button, {
  ButtonSize
} from '../../../../../shared/components/core-ui/button/button.component';
import { CustomPriceModel, UnitTypeUiModel } from '../../../../../shared/ui-models/unit-type.ui-model';
import Select, { ValueType, components } from 'react-select';
import { Tooltip, withStyles } from '@material-ui/core';
import { InputText, InputTextType } from '../../../../../shared/components/core-ui/input-text/input-text.component';

export interface ChooseApptProps {
  data: {
    disbaleDate: Date[];
    basePrice?: number;
    customPrices?: CustomPriceModel[];
    selectedUnitType: UnitTypeUiModel;
  };
  eventHandlers: {
    setDatesAndDiscountFees: (unitType: Date | Date[], discountAmount?: number, discountFees?: string[]) => void;
    proceedApptNextStep: (next: boolean) => void;
    cancelHandler: () => void;
  };
}

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '1.5rem' // Adjust the font size as needed
  }
})(Tooltip);

/**
 * functional component  ChooseAppt
 * @param { ChooseApptProps}
 */
export const ChooseAppt: React.FC<ChooseApptProps> = ({ data, eventHandlers }) => {
  const isMobileView = useWindowResizer();
  const [selectedDates, setSelectedDates] = useState<Date | Date[]>();
  const [fees, setFees] = useState<{ value: string; label: string }[] | null>(null);
  const [discount, setDiscount] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const MultiValue = (props) => {
    const maxToShow = props.selectProps.maxToShow;
    const length = props.getValue().length;
    const shouldBadgeShow = length > maxToShow;
    const displayLength = length - maxToShow;

    // Create a tooltip title with the selected items
    const tooltipTitle = props
      .getValue()
      .map((item) => item.label)
      .join(', ');

    if (props.index < maxToShow) {
      return <components.MultiValue {...props} />;
    } else if (props.index === maxToShow) {
      return (
        <CustomTooltip title={tooltipTitle}>
          <div className="root">
            {shouldBadgeShow && `+ ${displayLength} item${length !== 1 ? 's' : ''} selected`}
          </div>
        </CustomTooltip>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles['choose-appt']}>
      <Calendar
        data={{
          calenderView: true,
          disbaleDate: data.disbaleDate,
          showDateInput: false,
          basePriceTag: data.basePrice,
          customPriceTags: data.customPrices
        }}
        eventHandlers={{
          onChange: (val) => {
            eventHandlers.setDatesAndDiscountFees(val);
            setSelectedDates(val);
          }
        }}
      />
      <div className={styles['choose-appt__discount']}>
        <InputText
          data={{
            value: discount.value,
            placeholder: 'discount amount',
          }}
          config={{
            maxRows: 1,
            autoFocus: true,
            type: InputTextType.number,
          }}
          eventHandlers={{
            onChangeHandler: (value: string, isValid: boolean) => {
              setDiscount({ value, isValid });
            }
          }}
        />
        <Select
          value={fees}
          options={[{ value: 'Unit Price', label: 'rent' }].concat(
            (data.selectedUnitType?.fees || []).map((fee) => ({ value: fee.name, label: fee.name }))
          )}
          isMulti
          isSearchable
          placeholder="discount Fees"
          onChange={(value: ValueType<{ value: string; label: string }[], false>) => {
            setFees(value ? value : null);
          }}
          maxToShow={5}
          components={{ MultiValue }}
        />
        <div className={styles['choose-appt--discount-actions']}>
        <Button
          disabled={!Array.isArray(selectedDates) || !selectedDates.length || !fees?.length || !discount?.value}
          onClick={() =>{
            if(discount && fees && selectedDates) {
            eventHandlers.setDatesAndDiscountFees(selectedDates, discount.value , fees.map((fee)=>fee.value));
            }
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Apply discount
        </Button>
        <Button
          disabled={!Array.isArray(selectedDates) || !selectedDates.length}
          onClick={() =>{
            if(selectedDates){
              setFees(null);
              setDiscount({ value: '', isValid: false });
              eventHandlers.setDatesAndDiscountFees(selectedDates);
            }
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Remove discount
        </Button>
        </div>
      </div>
      <div className={styles['choose-appt--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
          }}
          tracking={{
            action: 'close',
            category: 'appt',
            label: 'choose-appt'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Cancel
        </Button>
        <Button
          disabled={!Array.isArray(selectedDates) || !selectedDates.length}
          onClick={() => eventHandlers.proceedApptNextStep(true)}
          tracking={{
            action: 'submit',
            category: 'appt',
            label: 'choose-appt'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          proceed
        </Button>
      </div>
    </div>
  );
};
