export const PERMISSIONS_LIST = {
  propertiesFullAccess: 'propertiesFullAccess',
  usersFullAccess: 'usersFullAccess',
  areasFullAccess: 'areasFullAccess',
  roomsFullAccess: 'roomsFullAccess',
  bookingsFullAccess: 'bookingsFullAccess',
  reservationsFullAccess: 'reservationsFullAccess',
  permissionsFullAccess: 'permissionsFullAccess',
  paymentsFullAccess: 'paymentsFullAccess',
  propertiesModifyAccess: 'propertiesModifyAccess',
  areasModifyAccess: 'areasModifyAccess',
  bookingsModifyAccess: 'bookingsModifyAccess',
  reservationsModifyAccess: 'reservationsModifyAccess',
  paymentsModifyAccess: 'paymentsModifyAccess',
  paymentsDeleteAccess: 'paymentsDeleteAccess',
  usersModifyAccess: 'usersModifyAccess',
  roomsModifyAccess: 'roomsModifyAccess',
  permissionsModifyAccess: 'permissionsModifyAccess'
};
